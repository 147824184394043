import { supabase } from "@/modules/supabase";
import { downloadImageFromStorage } from "./storage";

/**
 * Returns a product without a price tag
 *
 * @param {String} productCode A code that represents the identity of a product
 * @returns
 */
const getProductApi = async (productCode) => {
  const { data, error } = await supabase
    .from("Stock_Item_View")
    .select()
    .eq("CODE", productCode);
  console.log("get product api", data);
  console.log("get product api error", error);
  return data?.[0];
};

const getProductPictureUrlByProductCode = async (productCode) => {
  const { data, error } = await supabase
    .from("productCode_pictureUrl")
    .select()
    .eq("product_code", productCode);
  return { data, error };
};

/**
 * Returns products that correspond to a price tag.
 *
 * @param {String} productCode A code that represents the identity of a product
 * @param {String} priceTagId A string that represents the price tag ID
 * @returns
 */
const getProductWithPriceTagApi = async (productCode, priceTagId) => {
  const { data, error } = await supabase
    .from("Stock_Item_With_Price_Tags_View")
    .select()
    .match({ CODE: productCode, PRICETAG: priceTagId });
  console.log("getProductWithPriceTagApi api", data);
  console.log("getProductWithPriceTagApi api error", error);
  return data;
};

/**
 * Returns a list of products without price tag.
 *
 * @param {Integer} min The starting index of the list
 * @param {Integer} max The ending index of the list
 * @returns
 */
const getAllProductsApi = async (min, max) => {
  const { data, error } = await supabase
    .from("Stock_Item_View")
    .select()
    .order("DESCRIPTION", { ascending: true });
  console.log("get all products api", data);
  console.log("get all products api error", error);
  return data;
};

/**
 * Returns a list of product stock groups.
 * Example:
 * [
 *  {
 *      "id": 1,
 *      "STOCKGROUP": "S_01_CHICKEN",
 *      "count": 39
 *  },
 *  {
 *      "id": 2,
 *      "STOCKGROUP": "S_02_BEEF/DUCK/LAMB",
 *      "count": 16
 *  },
 * ]
 * @returns {Array} A list of product stock groups
 */
const getAllProductStockGroups = async () => {
  const { data, error } = await supabase.from("Stock_Item_STOCKGROUP").select();
  console.log("get getAllProductStockGroups api", data);
  console.log("get getAllProductStockGroups error", error);
  return data;
};

/**
 * Returns a price tag id when given a price tag code.
 * Example: Given P_C.O.D, returns 88
 *
 * @param {String} priceTagCode Code for price tag. Example: P_C.O.D
 * @returns
 */
const getPriceTagId = async (priceTagCode) => {
  const { data, error } = await supabase
    .from("Price_Tags_Base_View")
    .select()
    .eq("CODE", priceTagCode);
  console.log("getPriceTagId api", data);
  console.log("getPriceTagId api error", error);
  return data?.[0]?.ID;
};

/**
 * Returns a list of products with an associated pricetag.
 * Example: 88 returns all products with the price tag of 88.
 * Note: A price tag code can be used to retrieve the price tag ID using the `getPriceTag` API.
 *
 * @param {Integer} min The starting index of the list
 * @param {Integer} max The end index of the list
 * @param {String} priceTagId The key
 * @returns
 */
const getAllProductsWithPriceTagApi = async (min, max, priceTagId) => {
  const { data, error } = await supabase
    .from("Stock_Item_With_Price_Tags_View")
    .select()
    .match({ PRICETAG: priceTagId })
    .range(min, max)
    .order("DESCRIPTION", { ascending: true });
  console.log("getAllProductsWithPriceTagApi api", data);
  console.log("getAllProductsWithPriceTagApi api error", error);
  return data;
};

/**
 * Downloads an image blob from Supabase Storage.
 * Sets the local image url that points to the blob to local state.
 *
 * @param {String} path The name of the image in the storage bucket.
 */
const getProductImageUrlApi = async (path, width, height, quality) => {
  const getImageBlobFromStorage = async () => {
    const { data, error } = await downloadImageFromStorage(
      "product-images",
      path,
      {
        transform: {
          width,
          height,
          quality,
        },
      }
    );
    console.log("get product-images api", data);
    console.log("get product-images error", error);
    if (error) throw error;
    const url = URL.createObjectURL(data);
    return url;
  };
  try {
    const url = await getImageBlobFromStorage(path);
    return url;
  } catch (error) {
    console.log("Error downloading image: ", error);
  }
};

const upsertOneProduct = async (columns) => {
  const { data, error } = await supabase
    .from("productCode_pictureUrl")
    .upsert([columns]);
  console.log("upsertToDatabase success", data);
  console.log("upsertToDatabase error", error);
  return { data, error };
};

export {
  getProductApi,
  getProductPictureUrlByProductCode,
  getProductWithPriceTagApi,
  getAllProductsApi,
  getAllProductStockGroups,
  getPriceTagId,
  getAllProductsWithPriceTagApi,
  getProductImageUrlApi,
  upsertOneProduct,
};
