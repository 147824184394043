import { supabase } from "@/modules/supabase";

const getOneProfileById = async (id) => {
  const { data, error } = await supabase
    .from("profiles_view")
    .select()
    .eq("id", id);
  console.log("get profile_by_id_api", data);
  console.log("get profile_by_id_api error", error);
  return data?.[0];
};

const getOneProfileByEmail = async (email) => {
  const { data, error } = await supabase
    .from("profiles_view")
    .select("email")
    .eq("email", email);
  console.log("get profiles_view api", data);
  console.log("get profiles_view api error", error);
  return data?.[0];
};

const getProfilesViewApi = async () => {
  const { data, error } = await supabase.from("profiles_view").select();
  console.log("get profiles_view api", data);
  console.log("get profiles_view api error", error);
  return data;
};

const deleteProfileApi = async (id) => {
  const { data, error } = await supabase.auth.admin.deleteUser(id);
  console.log("delete profile api", data);
  console.log("delete profile api error", error);
};

export {
  getOneProfileById,
  getOneProfileByEmail,
  getProfilesViewApi,
  deleteProfileApi,
};
