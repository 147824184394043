/**
 * Returns a formatted stock group name
 * E.g. 'S_02_BEEF/DUCK/LAMB' returns 'BEEF, DUCK, LAMB'
 * @param {String} StockGroupDisplayName A string representing the stockgroup's display name
 * @returns
 */

import { toast } from "sonner";

function getStockGroupDisplayName(stockgroup) {
  console.log(stockgroup);
  try {
    return stockgroup
      .split("_")[2]
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(", ");
  } catch (error) {
    if (error instanceof TypeError) {
      console.error(
        `There was likely a problem with processing the following stock group: ${stockgroup}`
      );
    } else {
      console.error(error);
    }
  }

  return stockgroup;
}

export { getStockGroupDisplayName };
