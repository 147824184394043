import { UserProfilePlaceholderLarge } from "@/modules/core/components/ui";
import "./profile-page.css";

const ProfilePage = ({ session, profile }) => {
  return (
    <div className="profile-page">
      <h1>Profile</h1>
      <div className="profile-page-body">
        <section className="user-image-section">
          <UserProfilePlaceholderLarge />
        </section>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <span style={{ color: "grey", fontSize: "0.9em", margin: "5px 0" }}>
            First Name
          </span>
          <span style={{ color: "var(--font-grey)" }}>
            {profile?.first_name}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <span style={{ color: "grey", fontSize: "0.9em", margin: "5px 0" }}>
            Last Name
          </span>
          <span style={{ color: "var(--font-grey)" }}>
            {profile?.last_name}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <span style={{ color: "grey", fontSize: "0.9em", margin: "5px 0" }}>
            Email
          </span>
          <span style={{ color: "var(--font-grey)" }}>{profile?.email}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <span style={{ color: "grey", fontSize: "0.9em", margin: "5px 0" }}>
            Phone
          </span>
          <span style={{ color: "var(--font-grey)" }}>
            {session?.user?.phone}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <span
            style={{
              color: "grey",
              fontSize: "0.9em",
              margin: "5px 0",
              alignSelf: "start",
            }}
          >
            Address
          </span>
          <span style={{ color: "var(--font-grey)", textAlign: "right" }}>
            No. 44, Jalan Desa Melur 4/1,
            <br />
            Taman Bandar Connaught
            <br />
            56000 Cheras,
            <br />
            Kuala Lumpur, Malaysia.
          </span>
        </div>
      </div>
    </div>
  );
};

export { ProfilePage };
