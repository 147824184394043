import { useState, useEffect, createRef, memo } from "react";
import { Link, useParams } from "react-router-dom";
import {
  FiAlertCircle as OutOfStockIcon,
  FiHeart as FavoriteIcon,
  FiX as CloseIcon,
} from "react-icons/fi";
import { toast } from "sonner";
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
} from "@/modules/core/components/ui";
import { Product, ProductPicture } from "@/modules/core/components/gadgets";
import { formatPriceTag } from "@/modules/core/components/gadgets/products-interface/helpers";
import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import { getProductWithPriceTagApi } from "@/modules/core/api/products";
import {
  getActiveCartApi,
  getCartItemsApi,
  setCartItemsApi,
  insertCartApi,
} from "@/modules/core/api/cart";
import {
  getUserProductFavoriteForAProductApi,
  insertUserProductFavoriteApi,
  deleteUserProductFavoriteApi,
} from "@/modules/core/api/user_product_favorites";
import {
  BananaSpringRoll,
  BananaFritters,
  CocktailKaripap,
  Samosa,
} from "@/modules/img/food/snacks";
import { DurianMochi } from "@/modules/img/food/general";
import { Overlay } from "@/modules/core/components/ui";
import "./product-page.css";

/**
 * Retrieves a dimension number based on the size of the viewport.
 * This calculation is based on the CSS breakpoint in ./index.css
 *
 * @returns An Integer representing the dimension of the image
 */
function getImageDimension() {
  return Math.floor(
    window.innerWidth > 800 ? window.innerWidth / 1.5 : window.innerWidth
  );
}

function AddToCartButton({ isOutOfStock, handleAddToCart }) {
  return (
    <button
      className={
        isOutOfStock ? "add-to-cart-button-disabled" : "add-to-cart-button"
      }
      onClick={handleAddToCart}
      style={{ margin: "25px 0" }}
      disabled={isOutOfStock}
    >
      {isOutOfStock ? "Currently Unavailable" : "Add to Cart"}
    </button>
  );
}

const MemoizedProductPicture = memo(ProductPicture);
function Sizes({ sizes, product, handleSelectProduct }) {
  function Size({ size, price, isSelected }) {
    if (!size) return null;
    return (
      <div
        className={`size-list-item ${isSelected ? "highlight" : ""}`}
        onClick={() => handleSelectProduct(size)}
      >
        <div className="size-list-item-title">{size}</div>
        <div className="size-list-item-price">
          {formatPriceWithCurrency(price)}
        </div>
      </div>
    );
  }
  return (
    <div className="size-list">
      {sizes
        ?.sort?.((a, b) => Number(b.STOCKVALUE) - Number(a.STOCKVALUE))
        ?.map((size) => (
          <Size
            key={size.UOM}
            size={size.UOM}
            price={size.STOCKVALUE}
            isSelected={product.UOM === size.UOM}
          />
        ))}
    </div>
  );
}
const SimpleProductPage = ({ session, profile }) => {
  const { productCode } = useParams();
  const inputRef = createRef(null);
  const [isLoading, setLoading] = useState(false);
  const [productStore, setProductStore] = useState(null);
  const [product, setProduct] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [, setIsLoadingFavorite] = useState(false);
  const [input, setInput] = useState(1);
  const [showNotification, setNotification] = useState(false);
  async function getIsFavorite() {
    setIsLoadingFavorite(true);
    const data = await getUserProductFavoriteForAProductApi(
      profile?.id,
      productCode
    );
    if (data.length > 0) setIsFavorite(true);
    else setIsFavorite(false);
    setIsLoadingFavorite(false);
  }
  useEffect(() => {
    const getProductDetails = async () => {
      setLoading(true);
      const product = await getProductWithPriceTagApi(
        productCode,
        formatPriceTag(profile?.price_tag)
      );
      setProductStore(product);
      setProduct(product[0]);
      setLoading(false);
    };
    getProductDetails();
    getIsFavorite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDecrement = () => {
    if (input > 1) {
      setInput((prevState) => Number(prevState) - 1);
    }
  };
  const handleIncrement = () => {
    if (input < 999) {
      setInput((prevState) => Number(prevState) + 1);
    }
  };
  const handleTextChange = (e) => {
    let validated = e.target.value.replace(/[^0-9]+/g, "");
    if (validated.startsWith(0)) validated = validated.replace(0, "");
    if (validated > 999) validated = input;
    setInput(validated);
  };
  /**
   * Adds the item to the existing cart. If a cart does not already exist, a new cart is created.
   * Then, the item is added to the new cart.
   */
  const handleAddToCart = async (e) => {
    if (!input || input < 1) {
      inputRef.current.focus();
      toast.warning("Please set a quantity");
      return;
    }
    setInput(1);
    setNotification(true);
    let cart = await getActiveCartApi(session?.user?.id);
    if (!cart) {
      cart = await insertCartApi(session?.user?.id);
    }
    const cartItems = await getCartItemsApi(cart.id);
    const item = cartItems.find(
      (item) => Number(item.product_id) === Number(product.DTLKEY)
    );
    console.log("item", item);
    if (item) {
      // Add to existing items
      item.quantity += Number(input);
      setCartItemsApi(cart.id, product.DTLKEY, item.quantity);
    } else {
      // Add new item
      setCartItemsApi(cart.id, product.DTLKEY, Number(input));
    }
    console.log("cart", cart);
  };
  /**
   * Sets the product to the selected product type.
   * @param {String} selected The UOM of the selected product
   */
  const handleSelectProduct = (selected) => {
    const selectedProduct = productStore.find(
      (product) => product.UOM === selected
    );
    // Perform only when product is different from the current product
    if (selectedProduct.UOM !== product.UOM) {
      setProduct(productStore.find((product) => product.UOM === selected));
      setInput(1);
    }
  };
  /**
   * @returns Returns the total price of the product
   */
  const getTotalPrice = () => product?.STOCKVALUE * input;
  /**
   * Favorites a product if it has not been favorited.
   * Conversely, removes the product as a favorite if it has already been favorited.
   * @param {UUID} user_id A uuid representing a user's identity
   * @param {String} product_code A string representing a product's identity
   * @param {Boolean} is_favorite A boolean indicating if the product has been favorited by a user
   */
  async function handleFavoriteClick(user_id, product_code, is_favorite) {
    if (!is_favorite) {
      await insertUserProductFavoriteApi(user_id, product_code);
      await getIsFavorite();
    } else {
      await deleteUserProductFavoriteApi(user_id, product_code);
      await getIsFavorite();
    }
  }
  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      pathname: "/market",
      text: "Market",
      type: "link",
    },
    {
      text: product?.DESCRIPTION,
      type: "text",
    },
  ];

  return (
    <div className="product-page">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="product-page-body">
        {isLoading ? (
          <LoadingIndicator center />
        ) : (
          <>
            <div className="product-page-item">
              <div className="product-page-item-picture">
                <div className="gallery">
                  <div className="square">L</div>
                  <div className="square">O</div>
                  <div className="square">T</div>
                  <div className="square">U</div>
                  <div className="square">S</div>
                </div>
                <MemoizedProductPicture
                  productCode={productCode}
                  width={getImageDimension()}
                  height={getImageDimension()}
                  quality={80}
                />
              </div>
              <div className="product-page-item-details">
                <div className="product-info">
                  <div className="product-info-title">
                    <h3>{product?.DESCRIPTION}</h3>
                  </div>
                  <div className="user-actions">
                    <div
                      className="user-action-wrapper"
                      onClick={() =>
                        handleFavoriteClick(
                          profile?.id,
                          productCode,
                          isFavorite
                        )
                      }
                    >
                      <div className="user-action-icon-wrapper">
                        <FavoriteIcon
                          className={`favorite-icon ${isFavorite ? "fill" : "no-fill"}`}
                        />
                      </div>
                      <span className="user-action-text">Save Item</span>
                    </div>
                    {product?.is_out_of_stock && (
                      <div className="out-of-stock-label">
                        <OutOfStockIcon />
                        <span className="out-of-stock-text">Out Of Stock</span>
                      </div>
                    )}
                  </div>
                  <section className="product-info-sizes">
                    <Sizes
                      sizes={productStore}
                      product={product}
                      handleSelectProduct={handleSelectProduct}
                    />
                  </section>
                  <section className="product-info-dimensions">
                    <h4>Dimensions</h4>
                    <p>
                      <span className="dimension">{product?.DESCRIPTION2}</span>
                    </p>
                  </section>
                  <section className="product-info-description">
                    <h4>Description</h4>
                    <div className="product-info-description-text">
                      <p>{product?.REMARK1}</p>
                      <p>{product?.REMARK2}</p>
                    </div>
                  </section>
                </div>
                <div className="product-info-total-price">
                  <span>{formatPriceWithCurrency(getTotalPrice())}</span>
                </div>
                <div className="product-page-actions">
                  <div className="counter">
                    <button
                      className="counter-button"
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                    <input
                      ref={inputRef}
                      type="text"
                      value={input}
                      onChange={handleTextChange}
                      min="1"
                    ></input>
                    <button
                      className="counter-button"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                  <AddToCartButton
                    isOutOfStock={product?.is_out_of_stock}
                    handleAddToCart={handleAddToCart}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <section className="suggestions-section">
        <h4>Explore similar items</h4>
        <div className="suggestions-list">
          <Link to="/market">
            <Product
              img={BananaSpringRoll}
              title="Spring Roll"
              description="Banana Spring Roll"
            />
          </Link>
          <Link to="/market">
            <Product
              img={CocktailKaripap}
              title="Karipap"
              description="Cocktail Karipap"
            />
          </Link>
          <Link to="/market">
            <Product img={Samosa} title="Samosa" description="Samosa" />
          </Link>
          <Link to="/market">
            <Product
              img={BananaFritters}
              title="Banana Fritters"
              description="Goreng Pisang"
            />
          </Link>
          <Link to="/market">
            <Product
              img={DurianMochi}
              title="Mochi"
              description="Durian Mochi"
            />
          </Link>
        </div>
      </section>
      <div className="product-page-footer" style={{ padding: 50 }} />
      {showNotification && (
        <Overlay
          className="added-to-cart-notification"
          onClick={() => setNotification(false)}
        >
          <div
            className="added-to-cart-notification-message"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="item-details-header">
              <h2>Added to Cart</h2>
              <span
                className="close-btn"
                onClick={() => setNotification(false)}
              >
                <CloseIcon />
              </span>
            </div>
            <div className="added-to-cart-notification-message-body">
              <div className="img-container">
                <MemoizedProductPicture
                  productCode={productCode}
                  width={180}
                  height={270}
                  quality={80}
                />
              </div>
              <div className="item-details">
                <p>
                  <b>
                    {product?.DESCRIPTION} ({product?.UOM})
                  </b>
                </p>
                <div>
                  <div className="buttons">
                    <Button href="/market" wide>
                      Continue Shopping
                    </Button>
                    <Button href="/cart" color="green" wide>
                      Go to Cart
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </div>
  );
};

const ProductPage = memo(SimpleProductPage);

export { ProductPage };
